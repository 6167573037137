import { Box, Chip, MenuItem, TextField, Typography } from '@mui/material';
import { FC, useState, MouseEvent } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from 'design-system';

export const MultiDmcSelector: FC<{
  name: string;
  label: string;
  options: string[];
  helperText?: string;
}> = ({ name, label, options, helperText }) => {
  const [isAddingNewValue, setIsAddingNewValue] = useState(false);
  const [isEditingValue, setIsEditingValue] = useState(false);
  const [newValue, setNewValue] = useState('');
  const [editingValue, setEditingValue] = useState({ original: '', updated: '' });

  return (
    <Field<string[]> name={name}>
      {({ input }: { input: FieldInputProps<string[]> }) => {
        // Ensure input.value is always an array
        const selectedValues: string[] = Array.isArray(input.value) ? input.value : [];

        const handleAddValue = (value: string): void => {
          if (!value.trim() || selectedValues.includes(value)) {
            return;
          }
          // Create a new array with the new value
          const newValues: string[] = selectedValues.concat([value]);
          input.onChange(newValues);
          setNewValue('');
          setIsAddingNewValue(false);
        };

        const handleRemoveValue = (value: string): void => {
          input.onChange(selectedValues.filter((v) => v !== value));
        };

        const handleStartEditing = (value: string): void => {
          setEditingValue({ original: value, updated: value });
          setIsEditingValue(true);
          setIsAddingNewValue(false);
        };

        const handleSaveEdit = (): void => {
          if (
            !editingValue.updated.trim() ||
            (editingValue.updated !== editingValue.original &&
              selectedValues.includes(editingValue.updated))
          ) {
            setIsEditingValue(false);
            return;
          }

          const newValues = selectedValues.map((v) =>
            v === editingValue.original ? editingValue.updated : v,
          );
          input.onChange(newValues);
          setIsEditingValue(false);
        };

        // Show edit mode
        if (isEditingValue) {
          return (
            <Box>
              <Typography variant="bodySingle" fontWeight={500} mb={1}>
                {label}
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Edit DMC name"
                value={editingValue.updated}
                onChange={(e) =>
                  setEditingValue({ ...editingValue, updated: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSaveEdit();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Box
                        sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 500 }}
                        onClick={handleSaveEdit}
                      >
                        Save
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer', color: 'text.secondary' }}
                        onClick={() => setIsEditingValue(false)}
                      >
                        Cancel
                      </Box>
                    </Box>
                  ),
                }}
                autoFocus
              />
              {helperText && (
                <Typography color="text.secondary" mt={1} fontSize="0.75rem">
                  {helperText}
                </Typography>
              )}
            </Box>
          );
        }

        // Show text input when adding a new value
        if (isAddingNewValue) {
          return (
            <Box>
              <Typography variant="bodySingle" fontWeight={500} mb={1}>
                {label}
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter new DMC name"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddValue(newValue);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Box
                        sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 500 }}
                        onClick={() => handleAddValue(newValue)}
                      >
                        Add
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer', color: 'text.secondary' }}
                        onClick={() => setIsAddingNewValue(false)}
                      >
                        Cancel
                      </Box>
                    </Box>
                  ),
                }}
                autoFocus
              />
              {helperText && (
                <Typography color="text.secondary" mt={1} fontSize="0.75rem">
                  {helperText}
                </Typography>
              )}
            </Box>
          );
        }

        return (
          <Box>
            <Typography variant="bodySingle" fontWeight={500} mb={1}>
              {label}
            </Typography>
            <TextField
              select
              size="small"
              fullWidth
              value=""
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  handleAddValue(value);
                }
              }}
              InputProps={{
                startAdornment: selectedValues.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                      maxWidth: 'calc(100% - 32px)',
                      py: 0.5,
                    }}
                  >
                    {selectedValues.map((value: string) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={(e: MouseEvent) => {
                          e.stopPropagation();
                          handleRemoveValue(value);
                        }}
                        color="primary"
                        size="small"
                        sx={{
                          borderRadius: 1,
                          height: '28px',
                          m: '2px',
                          cursor: 'pointer',
                          '.MuiChip-label': {
                            padding: '0 8px',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                          },
                          '.MuiChip-deleteIcon': {
                            fontSize: '18px',
                            margin: '0 4px 0 -4px',
                          },
                        }}
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation();
                          handleStartEditing(value);
                        }}
                      />
                    ))}
                  </Box>
                ),
              }}
              SelectProps={{
                displayEmpty: true,
                renderValue: () =>
                  selectedValues.length > 0 ? '' : 'Select existing or add new',
                MenuProps: {
                  PaperProps: { style: { maxHeight: 300 } },
                },
              }}
              sx={{
                '.MuiInputBase-root': {
                  minHeight: selectedValues.length > 0 ? 'auto' : '40px',
                  padding: selectedValues.length > 0 ? '4px 14px 4px 8px' : '8px 14px',
                },
              }}
            >
              {options
                .filter((option) => !selectedValues.includes(option))
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}

              <Divider sx={{ my: 1 }} />

              <MenuItem
                value=""
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddingNewValue(true);
                }}
                sx={{ color: 'primary.main' }}
              >
                <AddIcon fontSize="small" sx={{ mr: 1 }} />
                Add new DMC
              </MenuItem>
            </TextField>

            {helperText && (
              <Typography color="text.secondary" mt={1} fontSize="0.75rem">
                {helperText}
              </Typography>
            )}
          </Box>
        );
      }}
    </Field>
  );
};
