import { generatePath } from 'react-router-dom';

export const RoutePath = {
  LOGIN: {
    value: '/login',
  },
  LOGIN_CALLBACK: {
    value: '/login/callback',
  },
  HOTELS: {
    value: '/hotels',
  },
  ITINERARY_TIMELINE: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/timeline',
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ITINERARY_TIMELINE.value, { adventureId, itineraryId }),
  },
  ITINERARY_PACK_LEADERS: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/pack-leaders',
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ITINERARY_PACK_LEADERS.value, { adventureId, itineraryId }),
  },
  ITINERARY_COSTS: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/costs/:view',
    generatePath: (adventureId: string, itineraryId: string, view: string) =>
      generatePath(RoutePath.ITINERARY_COSTS.value, { adventureId, itineraryId, view }),
  },
  ITINERARY_PRICES: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/prices/:view',
    generatePath: (adventureId: string, itineraryId: string, view: string) =>
      generatePath(RoutePath.ITINERARY_PRICES.value, { adventureId, itineraryId, view }),
  },
  ITINERARY_DEPARTURES: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/departures',
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ITINERARY_DEPARTURES.value, { adventureId, itineraryId }),
  },
  ITINERARY_DEPARTURE: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/departures/:departureId',
    generatePath: (adventureId: string, itineraryId: string, departureId: string) =>
      generatePath(RoutePath.ITINERARY_DEPARTURE.value, {
        adventureId,
        itineraryId,
        departureId,
      }),
  },
  ITINERARY_DEPARTURE_SIGN_OFF: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/departures/:departureId/sign-off',
    generatePath: (adventureId: string, itineraryId: string, departureId: string) =>
      generatePath(RoutePath.ITINERARY_DEPARTURE_SIGN_OFF.value, {
        adventureId,
        itineraryId,
        departureId,
      }),
  },
  ADD_ACCOMMODATION: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/timeline/accommodations/new',
    relative: 'accommodations/new',
    generateRelativePath: () => RoutePath.ADD_ACCOMMODATION.relative,
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ADD_ACCOMMODATION.value, { adventureId, itineraryId }),
  },
  VIEW_ACCOMMODATION: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/timeline/accommodations/:accommodationId/view',
    relative: 'accommodations/:accommodationId/view',
    generateRelativePath: (accommodationId: string) =>
      generatePath(RoutePath.VIEW_ACCOMMODATION.relative, {
        accommodationId,
      }),
    generatePath: (adventureId: string, itineraryId: string, accommodationId: string) =>
      generatePath(RoutePath.VIEW_ACCOMMODATION.value, {
        adventureId,
        itineraryId,
        accommodationId,
      }),
  },
  ADD_TRANSFER: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/timeline/transfers/new',
    relative: 'transfers/new',
    generateRelativePath: () => RoutePath.ADD_TRANSFER.relative,
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ADD_TRANSFER.value, { adventureId, itineraryId }),
  },
  VIEW_TRANSFER: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/timeline/transfers/:transferId/view',
    relative: 'transfers/:transferId/view',
    generateRelativePath: (transferId: string) =>
      generatePath(RoutePath.VIEW_TRANSFER.relative, { transferId }),
    generatePath: (adventureId: string, itineraryId: string, transferId: string) =>
      generatePath(RoutePath.VIEW_TRANSFER.value, {
        adventureId,
        itineraryId,
        transferId,
      }),
  },
  ADD_ACTIVITY: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/timeline/activities/new',
    relative: 'activities/new',
    generateRelativePath: () => RoutePath.ADD_ACTIVITY.relative,
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ADD_ACTIVITY.value, { adventureId, itineraryId }),
  },
  VIEW_ACTIVITY: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/timeline/activities/:activityId/view',
    relative: 'activities/:activityId/view',
    generateRelativePath: (activityId: string) =>
      generatePath(RoutePath.VIEW_ACTIVITY.relative, { activityId }),
    generatePath: (adventureId: string, itineraryId: string, activityId: string) =>
      generatePath(RoutePath.VIEW_ACTIVITY.value, {
        adventureId,
        itineraryId,
        activityId,
      }),
  },
  ITINERARY_DEPARTURE_VIEW_ACCOMMODATION: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/departures/:departureId/accommodations/:accommodationId/view',
    generatePath: (
      adventureId: string,
      itineraryId: string,
      departureId: string,
      accommodationId: string,
    ) =>
      generatePath(RoutePath.ITINERARY_DEPARTURE.value, {
        adventureId,
        itineraryId,
        departureId,
        accommodationId,
      }),
  },
  ITINERARY_DEPARTURE_VIEW_ACTIVITY: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/departures/:departureId/activities/:activityId/view',
    generatePath: (
      adventureId: string,
      itineraryId: string,
      departureId: string,
      activityId: string,
    ) =>
      generatePath(RoutePath.ITINERARY_DEPARTURE.value, {
        adventureId,
        itineraryId,
        departureId,
        activityId,
      }),
  },
  ITINERARY_DEPARTURE_VIEW_TRANSFER: {
    value:
      '/adventures/:adventureId/itineraries/:itineraryId/departures/:departureId/transfers/:transferId/view',
    generatePath: (
      adventureId: string,
      itineraryId: string,
      departureId: string,
      transferId: string,
    ) =>
      generatePath(RoutePath.ITINERARY_DEPARTURE.value, {
        adventureId,
        itineraryId,
        departureId,
        transferId,
      }),
  },
  ADD_ADVENTURE: {
    value: '/adventures/new',
  },
  ADVENTURES: {
    value: '/adventures',
  },
  ADVENTURE_ACTIONS: {
    value: '/adventures/:adventureId/actions',
    generatePath: (adventureId: string) =>
      generatePath(RoutePath.ADVENTURE_ACTIONS.value, { adventureId }),
  },
  ADVENTURE_ITINERARIES: {
    value: '/adventures/:adventureId/itineraries',
    generatePath: (adventureId: string) =>
      generatePath(RoutePath.ADVENTURE_ITINERARIES.value, { adventureId }),
  },
  ADVENTURE_SETTINGS: {
    value: '/adventures/:adventureId/settings',
    generatePath: (adventureId: string) =>
      generatePath(RoutePath.ADVENTURE_SETTINGS.value, { adventureId }),
  },
  ADVENTURE_ATTRIBUTES: {
    value: '/adventures/:adventureId/attributes',
    generatePath: (adventureId: string) =>
      generatePath(RoutePath.ADVENTURE_ATTRIBUTES.value, { adventureId }),
  },
  ADVENTURE_DEPARTURES: {
    value: '/adventures/:adventureId/departures',
    generatePath: (adventureId: string) =>
      generatePath(RoutePath.ADVENTURE_DEPARTURES.value, { adventureId }),
  },
  ADVENTURE_BULK_TIMELINE_ITEMS: {
    value: '/adventures/:adventureId/bulk-timeline-items',
    generatePath: (adventureId: string) =>
      generatePath(RoutePath.ADVENTURE_BULK_TIMELINE_ITEMS.value, { adventureId }),
  },
  ITINERARY_BULK_TIMELINE_ITEMS: {
    value: '/adventures/:adventureId/itineraries/:itineraryId/bulk-timeline-items',
    generatePath: (adventureId: string, itineraryId: string) =>
      generatePath(RoutePath.ITINERARY_BULK_TIMELINE_ITEMS.value, {
        adventureId,
        itineraryId,
      }),
  },
  FINANCE: {
    value: '/finance',
  },
  INNOVATION: {
    value: '/innovation',
  },
};
