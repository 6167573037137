import { FC, useState } from 'react';
import { Chip, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { BackButton, GenericError, PageHeading, ChainedText } from 'design-system';
import { RoutePath } from '@src/shared/routePath';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { AdventureLinks } from './AdventureLinks';
import { Protected } from '@src/authentication/Protected';
import EditIcon from '@mui/icons-material/Edit';
import { getCountryNames } from '@src/shared/countries/countryUtils';
import {
  AdventureHeaderDocument,
  AdventureWithItinerariesAndDeparturesDocument,
  SyncAdventureDeparturesToBookingEngineDocument,
  UserRole,
} from '@flashpack/graphql';
import { EditAdventureDialog } from '../common/EditAdventureDialog';
import {
  adventureStyleTitle,
  adventureStyleBackgroundColor,
} from '../../shared/adventure/util';
interface PropTypes {
  adventureId: string;
}
import SyncIcon from '@mui/icons-material/Sync';
import { LoadingButton } from '@mui/lab';
import { useToast } from '@src/shared/toast/useToast';

export const AdventureHeader: FC<PropTypes> = ({ adventureId }) => {
  const { data, loading, error } = useQuery(AdventureHeaderDocument, {
    variables: {
      id: adventureId,
    },
  });

  const { info: infoToast, error: errorToast } = useToast();
  const [
    syncAdventureDeparturesToBookingEngine,
    { loading: syncingAdventureDepartures },
  ] = useMutation(SyncAdventureDeparturesToBookingEngineDocument, {
    refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
  });

  const [editAdventureTitleDialog, setEditAdventureTitleDialog] = useState(false);

  if (loading) {
    return <Skeleton variant="text" width="25%" sx={{ fontSize: '30px' }} />;
  }

  if (error) {
    return <GenericError error={error} />;
  }

  if (!data) {
    throw new Error('Data not loaded');
  }

  const { countryCodes, region, title, type, ATOL, bookingEngineTourCode } =
    data?.adventure;
  const adventureData = {
    countries: countryCodes,
    region,
    title,
    type,
    ATOL,
    bookingEngineTourCode,
  };

  const syncDepartures = async () => {
    try {
      const syncResult = await syncAdventureDeparturesToBookingEngine({
        variables: {
          adventureId: data.adventure.id,
        },
      });
      infoToast(
        `${syncResult.data?.syncAdventureDeparturesToBookingEngine.syncedCount} departure(s) synced to the Booking Engine. ${syncResult.data?.syncAdventureDeparturesToBookingEngine.notSyncedCount} departure(s) did not sync`,
      );
    } catch (e) {
      if (e instanceof ApolloError) {
        errorToast(`Could not sync departures to booking engine: ${e.message}`);
      } else {
        errorToast(`Could not sync departures to booking engine: ${e as string}`);
      }
    }
  };

  return (
    <>
      <BackButton data-testid="adventures-back-button" href={RoutePath.ADVENTURES.value}>
        All adventures
      </BackButton>
      <Protected roles={[UserRole.Flashpack]}>
        <EditAdventureDialog
          adventureId={adventureId}
          adventureData={adventureData}
          open={editAdventureTitleDialog}
          onClose={() => setEditAdventureTitleDialog(false)}
        />
      </Protected>

      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack direction={'row'} spacing={2}>
          <PageHeading data-testid="adventure-code">
            <ChainedText
              parts={[
                data.adventure.title
                  ? data.adventure.title
                  : getCountryNames(data.adventure?.code),
                data.adventure.bookingEngineTourCode,
              ]}
            />
          </PageHeading>
          <Protected roles={[UserRole.Flashpack]}>
            <Tooltip arrow placement="bottom" title="Edit Adventure Title">
              <IconButton onClick={() => setEditAdventureTitleDialog(true)}>
                <EditIcon htmlColor="#999999" />
              </IconButton>
            </Tooltip>
          </Protected>
        </Stack>
        <Stack alignItems="flex-end" gap={1}>
          <Protected roles={[UserRole.Flashpack]}>
            <Tooltip arrow title="Sync adventure departures to the booking engine">
              <LoadingButton
                variant="contained"
                sx={{ width: 140 }}
                onClick={() => void syncDepartures()}
                loading={syncingAdventureDepartures}
              >
                <SyncIcon />
                <Typography>Sync</Typography>
              </LoadingButton>
            </Tooltip>
          </Protected>
          <AdventureLinks {...data.adventure} />
        </Stack>
      </Stack>
      <Stack direction="row" gap={2}>
        {data.adventure.type.length > 0 && (
          <Chip
            size={'small'}
            label={
              <Typography variant="captionSingle">
                {adventureStyleTitle(data.adventure.style)}
              </Typography>
            }
            sx={{
              background: adventureStyleBackgroundColor(data.adventure.style),
              width: 'fit-content',
            }}
          />
        )}
        {data.adventure.ATOL && (
          <Typography sx={{ color: 'principal.grey100' }} variant="captionSingle">
            ATOL Protected
          </Typography>
        )}
      </Stack>
    </>
  );
};
