import { PackLeader } from '@flashpack/graphql';
import { FC } from 'react';
import { Avatar, IconButton, Tooltip, Typography } from '@mui/material';
import { EmptyAvatarIcon } from 'design-system';
import { StopPropagationWrapper } from '@src/design-system/StopPropagationWrapper';

interface PropTypes {
  disabled?: boolean;
  packLeaders?: Omit<PackLeader, 'title'>[];
  onClick?: () => void;
}
export const DeparturePackLeader: FC<PropTypes> = ({
  disabled,
  packLeaders,
  onClick,
}) => {
  const packLeadersAssigned = packLeaders?.length !== 0;

  if (packLeadersAssigned) {
    return (
      <StopPropagationWrapper allowDefault={false}>
        <Tooltip title={packLeaders?.map((packLeader) => packLeader.name).join(', ')}>
          <span>
            <IconButton
              sx={{ p: 0 }}
              disabled={disabled}
              data-testid="set-pack-leader"
              onClick={onClick}
            >
              {packLeaders?.map((packLeader) => (
                <PackLeaderAvatar key={packLeader.id} packLeader={packLeader} />
              ))}
            </IconButton>
          </span>
        </Tooltip>
      </StopPropagationWrapper>
    );
  } else {
    return (
      <StopPropagationWrapper>
        <Tooltip title={'Assign pack leader'}>
          <span>
            <IconButton
              sx={{ p: 0 }}
              disabled={disabled}
              data-testid="add-pack-leader"
              onClick={onClick}
            >
              <EmptyAvatarIcon sx={{ width: 34, height: 34 }} />
            </IconButton>
          </span>
        </Tooltip>
      </StopPropagationWrapper>
    );
  }
};

interface AvatarPropTypes {
  packLeader: Omit<PackLeader, 'title'>;
  size?: number;
  fontSize?: number;
}

export const PackLeaderAvatar: FC<AvatarPropTypes> = ({ packLeader, size, fontSize }) => {
  const names = packLeader.name.split(' ', 2);
  const initials = names.map((word) => word[0]);
  const avatarSize = size ?? 34;
  return (
    <Avatar
      sx={{
        p: 2,
        mr: 0.5,
        width: avatarSize,
        height: avatarSize,
        bgcolor: 'principal.black',
        fontSize: fontSize ?? 12,
      }}
    >
      <Typography variant="label">{initials}</Typography>
    </Avatar>
  );
};
