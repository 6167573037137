import { AdventureStyle, AdventureTypes, ItineraryHeaderQuery } from '@flashpack/graphql';
import { getCountryNames } from '../countries/countryUtils';

export const adventureCodeToIndividualCountryCodes = (adventureCode: string) =>
  adventureCode.split('_').slice(0, -1);

export const itenararyAdventureTitle = (
  adventureTitle: string | null | undefined,
  data: ItineraryHeaderQuery,
) =>
  `${adventureTitle ? adventureTitle : getCountryNames(data.itinerary.adventure.code)} (${
    data.itinerary.adventure.bookingEngineTourCode
  })`;

const adventureTypeInfo = {
  [AdventureTypes.Classic]: {
    color: '#edca79',
    title: 'Classic',
  },
  [AdventureTypes.ClassicSlow]: {
    color: '#ed8579',
    title: 'Classic Slow',
  },
  [AdventureTypes.BucketList]: {
    color: '#86bbe3',
    title: 'Bucket List',
  },
  [AdventureTypes.Challenge]: {
    color: '#ba9cd9',
    title: 'Challenge',
  },
  [AdventureTypes.ShortStay]: {
    color: '#f1a8c1',
    title: 'Short Stay',
  },
};

export const adventureTypeBackgroundColor = function (value: AdventureTypes) {
  return adventureTypeInfo[value].color;
};

export const adventureTypeTitle = function (value?: AdventureTypes) {
  if (!value || !value.length) {
    return '';
  }
  return adventureTypeInfo[value].title;
};

const adventureStyleInfo = {
  [AdventureStyle.Classic]: {
    color: '#edca79',
    title: 'Classic',
  },
  [AdventureStyle.Balanced]: {
    color: '#ed8579',
    title: 'Balanced',
  },
  [AdventureStyle.ClassicShort]: {
    color: '#f1a8c1',
    title: 'Classic Short',
  },
};

export const adventureStyleBackgroundColor = (value: AdventureStyle) =>
  adventureStyleInfo[value].color;

export const adventureStyleTitle = (value?: AdventureStyle) =>
  value ? adventureStyleInfo[value].title : '';
