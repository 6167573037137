import { Tabs, Tab } from '@mui/material';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { isVisibleTo } from '@src/authentication/Protected';
import LinkBehavior from '@src/design-system/LinkBehaviour';
import { UserRole } from '@flashpack/graphql';
import { hasHandle, isString } from '@src/shared/hasHandle';
import { RoutePath } from '@src/shared/routePath';
import { useMatches } from 'react-router-dom';
import { AdventureTab, ADVENTURE_TAB_HANDLE } from './AdventurePage';
import { Environment, environmentIs } from '@src/app/environment';
import { adventureIdsForBetaReleaseDepartureSignOffs } from '../common/utils';

export const AdventureTabs = ({ adventureId }: { adventureId: string }) => {
  const { currentUser } = useAuthorization();
  const matches = useMatches();
  const adventureTabMatch = matches.find(hasHandle(ADVENTURE_TAB_HANDLE, isString));
  const adventureTab = adventureTabMatch?.handle.adventureTab;
  return (
    <Tabs sx={{ marginTop: 2, marginBottom: 2 }} value={adventureTab}>
      {/* TODO: enable on prod for all adventures when full release */}
      {(!environmentIs(Environment.Production) ||
        adventureIdsForBetaReleaseDepartureSignOffs.includes(adventureId)) && (
        <Tab
          label={AdventureTab.ACTIONS}
          value={AdventureTab.ACTIONS}
          href={RoutePath.ADVENTURE_ACTIONS.generatePath(adventureId)}
          LinkComponent={LinkBehavior}
          data-testid="adventure-actions-tab"
        />
      )}
      <Tab
        label={AdventureTab.ITINERARIES}
        value={AdventureTab.ITINERARIES}
        href={RoutePath.ADVENTURE_ITINERARIES.generatePath(adventureId)}
        LinkComponent={LinkBehavior}
        data-testid="adventure-itineraries-tab"
      />
      <Tab
        icon={AdventureTab.DEPARTURES}
        value={AdventureTab.DEPARTURES}
        href={RoutePath.ADVENTURE_DEPARTURES.generatePath(adventureId)}
        LinkComponent={LinkBehavior}
        data-testid="adventure-departures-tab"
      />
      {isVisibleTo(currentUser, [UserRole.Flashpack]) && (
        <>
          <Tab
            icon={AdventureTab.SETTINGS}
            value={AdventureTab.SETTINGS}
            href={RoutePath.ADVENTURE_SETTINGS.generatePath(adventureId)}
            LinkComponent={LinkBehavior}
            data-testid="adventure-settings-tab"
          />
          <Tab
            icon={AdventureTab.ATTRIBUTES}
            value={AdventureTab.ATTRIBUTES}
            href={RoutePath.ADVENTURE_ATTRIBUTES.generatePath(adventureId)}
            LinkComponent={LinkBehavior}
            data-testid="adventure-attributes-tab"
          />
        </>
      )}
    </Tabs>
  );
};
