import { FC, useEffect, useMemo, useState } from 'react';
import { ActivityIcon, GenericError } from 'design-system';
import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import { EngagementFieldsFragment, UserRole } from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { SharedTabsContextProvider } from '@src/shared/context/SharedTabsContextProvider';
import { CommentFormState } from '@src/shared/context/SharedTabsContext';
import { EngagementCommentsTab } from '@src/itinerary/common/EngagementCommentsTab';
import { ActivityInfoTab } from './ActivityInfoTab';
import { toEditFormValues } from '../common/activityFormMapper';
import { EditActivityFormValues } from './ViewEditActivityForm';
import { useCommentsTabOrDefault } from '@src/shared/timeline/util';
import { isVisibleTo } from '@src/authentication/Protected';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { EngagementLocationTab } from '@src/itinerary/common/EngagementLocationTab';
interface PropTypes {
  getActivity: (id: string) => EngagementFieldsFragment;
  isTimelineLocked?: boolean;
  isEditable: boolean;
}

export const ViewEditActivityPage: FC<PropTypes> = ({
  getActivity,
  isTimelineLocked,
  isEditable,
}) => {
  const { currentUser } = useAuthorization();
  const { activityId } = useRequiredParams(['activityId']);
  const engagement = useMemo(() => getActivity(activityId), [activityId, getActivity]);

  const [selectedTab, setSelectedTab] = useCommentsTabOrDefault('information');

  const initialActivityFormState = toEditFormValues(engagement);

  const [activityFormState, setActivityFormState] = useState<EditActivityFormValues>(
    initialActivityFormState,
  );

  const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);

  const handleTabClick = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const [commentFormState, setCommentFormState] = useState<CommentFormState>({
    content: '',
  });

  useEffect(() => {
    setActivityFormState(toEditFormValues(engagement));
    setHasPendingChanges(false);
  }, [activityId, engagement]);

  if (!engagement) {
    return (
      <ItineraryDrawer title="Activity" icon={<ActivityIcon />}>
        <GenericError error={'This engagement does not seem to exist'} />
      </ItineraryDrawer>
    );
  }

  if (!engagement.activity) {
    return (
      <ItineraryDrawer title="Activity" icon={<ActivityIcon />}>
        <GenericError error={"This engagement isn't an activity"} />
      </ItineraryDrawer>
    );
  }

  const shouldWarnForUnsavedChanges =
    hasPendingChanges || commentFormState.content !== '';

  return (
    <ItineraryDrawer
      title="Activity"
      icon={<ActivityIcon />}
      warnForChangesNotSaved={shouldWarnForUnsavedChanges}
    >
      <SharedTabsContextProvider
        value={{
          commentForm: {
            state: commentFormState,
            setState: setCommentFormState,
          },
          activityForm: {
            state: activityFormState,
            setState: setActivityFormState,
            hasPendingChanges: hasPendingChanges,
            setHasPendingChanges: setHasPendingChanges,
          },
        }}
      >
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabClick}>
            <Tab label="Information" value="information" data-testid="information-tab" />
            <Tab label="Comments" value="comments" data-testid="comments-tab" />
            {isVisibleTo(currentUser, [UserRole.Flashpack]) && (
              <Tab label="Website Map" value="location" data-testid="location-tab" />
            )}
          </TabList>
          <TabPanel value="information" sx={{ padding: 0 }}>
            <ActivityInfoTab
              isTimelineLocked={isTimelineLocked}
              isEditable={isEditable}
            />
          </TabPanel>
          <TabPanel value="comments" sx={{ padding: 0 }}>
            <EngagementCommentsTab engagement={engagement} />
          </TabPanel>
          <TabPanel value="location" sx={{ padding: 0 }}>
            <EngagementLocationTab engagement={engagement} />
          </TabPanel>
        </TabContext>
      </SharedTabsContextProvider>
    </ItineraryDrawer>
  );
};
