import { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import {
  EngagementFieldsFragment,
  EditEngagementLocationDocument,
} from '@flashpack/graphql';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FreeTextFormField } from 'design-system';
import { useMutation } from '@apollo/client';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { Decimal } from 'decimal.js';

interface PropTypes {
  engagement: EngagementFieldsFragment;
}

interface LocationFormState {
  startName: string;
  startLatitude: string;
  startLongitude: string;
  endName?: string;
  endLatitude?: string;
  endLongitude?: string;
}

export const EngagementLocationTab: FC<PropTypes> = ({ engagement }) => {
  const { success: successToast, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const [editLocation] = useMutation(EditEngagementLocationDocument);

  const isTransfer = !!engagement.transfer;

  const initialValues: LocationFormState = {
    startName: engagement.startMapLocation?.name ?? '',
    startLatitude: engagement.startMapLocation?.latitude?.toString() ?? '',
    startLongitude: engagement.startMapLocation?.longitude?.toString() ?? '',
    ...(isTransfer && {
      endName: engagement.endMapLocation?.name ?? '',
      endLatitude: engagement.endMapLocation?.latitude?.toString() ?? '',
      endLongitude: engagement.endMapLocation?.longitude?.toString() ?? '',
    }),
  };

  const handleSubmit = useCallback(
    async (formData: LocationFormState) => {
      await safeMutation(
        editLocation({
          variables: {
            input: {
              id: engagement.id,
              startMapLocation: {
                name: formData.startName,
                latitude: new Decimal(formData.startLatitude),
                longitude: new Decimal(formData.startLongitude),
              },
              ...(isTransfer && {
                endMapLocation: {
                  name: formData.endName!,
                  latitude: new Decimal(formData.endLatitude!),
                  longitude: new Decimal(formData.endLongitude!),
                },
              }),
            },
          },
        }),
        {
          onSuccess: () => {
            successToast('Location successfully updated');
          },
          onError: () => {
            errorToast(
              'There was an issue while updating the location - try again later',
            );
          },
        },
      );
    },
    [engagement.id, editLocation, safeMutation, successToast, errorToast, isTransfer],
  );

  const validate = (values: LocationFormState) => {
    const errors: Partial<LocationFormState> = {};

    // Validate start location
    if (!values.startName) {
      errors.startName = 'Name is required';
    }

    if (!values.startLatitude) {
      errors.startLatitude = 'Latitude is required';
    } else if (
      isNaN(Number(values.startLatitude)) ||
      Number(values.startLatitude) < -90 ||
      Number(values.startLatitude) > 90
    ) {
      errors.startLatitude = 'Invalid latitude (must be between -90 and 90)';
    }

    if (!values.startLongitude) {
      errors.startLongitude = 'Longitude is required';
    } else if (
      isNaN(Number(values.startLongitude)) ||
      Number(values.startLongitude) < -180 ||
      Number(values.startLongitude) > 180
    ) {
      errors.startLongitude = 'Invalid longitude (must be between -180 and 180)';
    }

    // Validate end location for transfers
    if (isTransfer) {
      if (!values.endName) {
        errors.endName = 'Name is required';
      }

      if (!values.endLatitude) {
        errors.endLatitude = 'Latitude is required';
      } else if (
        isNaN(Number(values.endLatitude)) ||
        Number(values.endLatitude) < -90 ||
        Number(values.endLatitude) > 90
      ) {
        errors.endLatitude = 'Invalid latitude (must be between -90 and 90)';
      }

      if (!values.endLongitude) {
        errors.endLongitude = 'Longitude is required';
      } else if (
        isNaN(Number(values.endLongitude)) ||
        Number(values.endLongitude) < -180 ||
        Number(values.endLongitude) > 180
      ) {
        errors.endLongitude = 'Invalid longitude (must be between -180 and 180)';
      }
    }

    return errors;
  };

  return (
    <Stack spacing={3} pt={2}>
      <Typography variant="Body M" color="text.secondary">
        {isTransfer
          ? 'These are the start and end location pins that will be displayed on the itinerary map on the website.'
          : 'This is the location pin that will be displayed on the itinerary map on the website.'}
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={(form) => void handleSubmit(form)}>
            <Stack spacing={3}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                {isTransfer ? 'Start Location' : 'Location'}
              </Typography>
              <Stack spacing={1}>
                <Typography sx={{ color: 'principal.grey70' }}>Location Name</Typography>
                <FreeTextFormField
                  name="startName"
                  placeholder="Enter location name"
                  required
                  data-testid="start-location-name-input"
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{ color: 'principal.grey70' }}>Latitude</Typography>
                <FreeTextFormField
                  name="startLatitude"
                  placeholder="Enter latitude"
                  required
                  data-testid="start-latitude-input"
                  helperText="Enter a value between -90 and 90"
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{ color: 'principal.grey70' }}>Longitude</Typography>
                <FreeTextFormField
                  name="startLongitude"
                  placeholder="Enter longitude"
                  required
                  data-testid="start-longitude-input"
                  helperText="Enter a value between -180 and 180"
                />
              </Stack>

              {isTransfer && (
                <>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                    End Location
                  </Typography>
                  <Stack spacing={1}>
                    <Typography sx={{ color: 'principal.grey70' }}>
                      Location Name
                    </Typography>
                    <FreeTextFormField
                      name="endName"
                      placeholder="Enter location name"
                      required
                      data-testid="end-location-name-input"
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Typography sx={{ color: 'principal.grey70' }}>Latitude</Typography>
                    <FreeTextFormField
                      name="endLatitude"
                      placeholder="Enter latitude"
                      required
                      data-testid="end-latitude-input"
                      helperText="Enter a value between -90 and 90"
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Typography sx={{ color: 'principal.grey70' }}>Longitude</Typography>
                    <FreeTextFormField
                      name="endLongitude"
                      placeholder="Enter longitude"
                      required
                      data-testid="end-longitude-input"
                      helperText="Enter a value between -180 and 180"
                    />
                  </Stack>
                </>
              )}

              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  loading={submitting}
                  disabled={pristine || submitting}
                  type="submit"
                  variant="contained"
                  color="success"
                  data-testid="location-save-button"
                >
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        )}
      />
    </Stack>
  );
};
