import { useMutation, useQuery } from '@apollo/client';
import {
  AdventureHeaderDocument,
  AdventureStyle,
  AdventureTag,
  UpdateAdventureAttributesDocument,
  AdventureWildlifeLevel,
  AdventureActivityLevel,
  AdventureFoodLevel,
  AdventurePhysicalityLevel,
  AdventureDownTimeLevel,
  DmcNamesDocument,
} from '@flashpack/graphql';
import { Typography, Stack, Box, Skeleton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useToast } from '@src/shared/toast/useToast';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { GenericError } from 'design-system';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { AutocompleteField } from '@src/design-system/forms/autocomplete/AutocompleteField';
import { Validator } from '@src/design-system/forms/validators';
import { SeasonalityCalendar } from './seasonality';
import SeasonalityCalendarField, {
  createDefaultCalendar,
} from './SeasonalityCalendarField';
import { MultiDmcSelector } from './MultiDmcSelector';

type LevelLabel = 'HIGH' | 'MEDIUM' | 'LOW';
const ACTIVITY_LEVEL_LABELS: Record<LevelLabel, string> = {
  HIGH: 'Active',
  MEDIUM: 'Moderate',
  LOW: 'Relaxed',
};

interface AdventureAttributesFormValues {
  tags: AdventureTag[];
  activityLevel: AdventureActivityLevel;
  foodLevel: AdventureFoodLevel;
  wildlifeLevel: AdventureWildlifeLevel;
  physicalityLevel: AdventurePhysicalityLevel;
  downTimeLevel: AdventureDownTimeLevel;
  style: AdventureStyle;
  seasonalityCalendar?: SeasonalityCalendar;
  dmcNames: string[];
}

const createEnumFieldProps = (enumObject: Record<string, string>) => ({
  options: Object.values(enumObject).map((value) => ({ value })),
  getOptionValue: (option: { value: string }) => option.value,
  getOptionLabel: (option: { value: string }) => formatDisplayText(option.value),
  fieldProps: { validate: Validator.required },
  fullWidth: true,
  helperText: ' ',
});

export const AdventureAttributesTab: FC = () => {
  const { adventureId } = useRequiredParams(['adventureId']);
  const { success, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const [seasonalityCalendar, setSeasonalityCalendar] = useState<SeasonalityCalendar>(
    createDefaultCalendar(),
  );
  const [isSeasonalityCalendarDirty, setIsSeasonalityCalendarDirty] = useState(false);

  const { data, loading, error } = useQuery(AdventureHeaderDocument, {
    variables: { id: adventureId },
  });

  const { data: dmcNamesData } = useQuery(DmcNamesDocument);
  const availableDmcNames = dmcNamesData?.dmcNames || [];

  const [updateAdventureAttributes] = useMutation(UpdateAdventureAttributesDocument);

  useEffect(() => {
    if (data?.adventure?.seasonalityCalendar) {
      const typedCalendar = data.adventure.seasonalityCalendar as SeasonalityCalendar;
      if (Object.keys(typedCalendar).length > 0) {
        setSeasonalityCalendar(typedCalendar);
      }
    }
  }, [data?.adventure?.seasonalityCalendar]);

  if (loading) {
    return <Skeleton variant="text" width="25%" sx={{ fontSize: '30px' }} />;
  }
  if (error) {
    return <GenericError error={error} />;
  }
  if (!data) {
    throw new Error('Data not loaded');
  }

  const handleSubmit = async (values: AdventureAttributesFormValues) => {
    const input = {
      id: adventureId,
      tags: values.tags,
      activityLevel: values.activityLevel,
      foodLevel: values.foodLevel,
      wildlifeLevel: values.wildlifeLevel,
      physicalityLevel: values.physicalityLevel,
      downTimeLevel: values.downTimeLevel,
      style: values.style,
      seasonalityCalendar,
      dmcNames: values.dmcNames,
    };

    await safeMutation(updateAdventureAttributes({ variables: { input } }), {
      onSuccess: () => {
        success('Adventure updated');
        setIsSeasonalityCalendarDirty(false);
      },
      onError: () => errorToast('Failed to update Adventure'),
    });
  };

  // Get the initial values from the adventure data
  const initialValues: AdventureAttributesFormValues = {
    tags: data.adventure.tags || [],
    activityLevel: data.adventure.activityLevel,
    foodLevel: data.adventure.foodLevel,
    wildlifeLevel: data.adventure.wildlifeLevel,
    physicalityLevel: data.adventure.physicalityLevel,
    downTimeLevel: data.adventure.downTimeLevel,
    style: data.adventure.style,
    dmcNames: data.adventure.dmcNames || [],
  };

  return (
    <Form<AdventureAttributesFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting, dirty }) => {
        return (
          <form onSubmit={(e) => void handleSubmit(e)}>
            <Stack sx={{ mt: 4, gap: 1 }}>
              <FormHeader
                submitting={submitting}
                dirty={dirty || isSeasonalityCalendarDirty}
              />
              <AttributeFields availableDmcNames={availableDmcNames} />
              <LevelsSection />
              <SeasonalityCalendarField
                calendar={seasonalityCalendar}
                onChange={(value) => {
                  setSeasonalityCalendar(value);
                  setIsSeasonalityCalendarDirty(true);
                }}
              />
            </Stack>
          </form>
        );
      }}
    />
  );
};

const FormHeader: FC<{ submitting: boolean; dirty: boolean }> = ({
  submitting,
  dirty,
}) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Typography variant="h2" mb={3}>
      Adventure Attributes
    </Typography>
    <LoadingButton
      loading={submitting}
      disabled={!dirty}
      type="submit"
      variant="contained"
      color="primary"
      sx={{ minWidth: 120 }}
    >
      Save
    </LoadingButton>
  </Stack>
);

const AttributeFields: FC<{ availableDmcNames: string[] }> = ({ availableDmcNames }) => (
  <Stack sx={{ maxWidth: 600, gap: 2 }}>
    <AutocompleteField<{ value: string }, false, true, false>
      name="style"
      label="Style"
      {...createEnumFieldProps(AdventureStyle)}
    />
    <AutocompleteField<{ value: string }, true, true, false>
      name="tags"
      label="Tags"
      multiple
      {...createEnumFieldProps(AdventureTag)}
      fieldProps={{
        validate: Validator.required,
        format: (value: string[]) => value.slice(0, 3),
      }}
      helperText="Maximum 3 tags allowed"
    />
    <Box sx={{ mb: 1 }}>
      <MultiDmcSelector
        name="dmcNames"
        label="DMCs"
        options={availableDmcNames}
        helperText="Select existing DMCs or add new ones"
      />
    </Box>
  </Stack>
);

const LevelsSection: FC = () => (
  <AttributeSection title="Levels">
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 3,
        '& .MuiFormControl-root': { minWidth: 0 },
      }}
    >
      <AutocompleteField<{ value: string }, false, true, false>
        name="activityLevel"
        label="Activity"
        options={Object.values(AdventureActivityLevel).map((value) => ({ value }))}
        getOptionValue={(option) => option.value}
        getOptionLabel={({ value }) =>
          ACTIVITY_LEVEL_LABELS[value as LevelLabel] || formatDisplayText(value)
        }
        fieldProps={{ validate: Validator.required }}
        fullWidth
        helperText=" "
      />
      <AutocompleteField<{ value: string }, false, true, false>
        name="foodLevel"
        label="Food"
        {...createEnumFieldProps(AdventureFoodLevel)}
      />
      <AutocompleteField<{ value: string }, false, true, false>
        name="wildlifeLevel"
        label="Wildlife"
        {...createEnumFieldProps(AdventureWildlifeLevel)}
      />
      <AutocompleteField<{ value: string }, false, true, false>
        name="physicalityLevel"
        label="Physicality"
        {...createEnumFieldProps(AdventurePhysicalityLevel)}
      />
      <AutocompleteField<{ value: string }, false, true, false>
        name="downTimeLevel"
        label="Down Time"
        {...createEnumFieldProps(AdventureDownTimeLevel)}
      />
    </Box>
  </AttributeSection>
);

const AttributeSection: FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <Box>
    <Typography variant="bodySingle" sx={{ mb: 2, fontWeight: 500 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

const formatDisplayText = (text: string): string => {
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
