import { ThemeOptions, alpha, createTheme, darken, lighten } from '@mui/material/styles';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import * as React from 'react';
import { useTheme, styled } from '@mui/material';
import { createBreakpoints } from '@mui/system';

// TODO: add Link behavior
import '@fontsource/heebo/700.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/400.css';
import './fonts/fonts.css';

export const defaultFontFamily = [
  '"Twemoji Country Flags"',
  'Euclid Square',
  'Heebo',
  'sans-serif',
].join(', ');

export const letterSpacing = (percentage: number) => {
  return `${(percentage / 100).toFixed(2)}em`;
};

export { useTheme, styled, alpha, lighten };

export const Colors = {
  principal: {
    black: '#202020',
    grey70: '#717171',
    grey50: '#cfcfcf',
    grey30: '#f6f6f6',
    white: '#ffffff',
  },
  brand: {
    jungle: '#004d40',
    crystal: '#ccf5e3',
    earth: '#3d0d0d',
    sunset: '#e58a00',
    midnight: '#300d4d',
    sky: '#E0EDED',
  },
  system: {
    amber100: '#fcc208',
    amber70: '#fdd452',
    amber50: '#fde183',
    amber30: '#feedb5',
    amber10: '#fff9e6',
    red100: '#ff4a1a',
    red70: '#ff805f',
    red50: '#ffa48c',
    red30: '#ffc9ba',
    red10: '#ffede8',
    green100: '#17b078',
    green70: '#5dc8a1',
    green50: '#8bd7bb',
    green30: '#b9e7d6',
    green10: '#e8f7f1',
    greenLight: '#E3FAE8',
    blue100: '#4687E8',
    blue70: '#7EABEF',
    blue50: '#A3C3F3',
    blue30: '#C8DBF8',
    blue10: '#EDF3FD',
    blueLight: '#E6FBFF',
    purple100: '#A146E8',
    purple70: '#BD7EEF',
    purple50: '#D0A3F3',
    purple30: '#E3C8F8',
    purple10: '#F6EDFD',
    warning: '#F0C000',
  },
};

const HOVER_ALPHA = 0.7;

export type ColorsType = typeof Colors;

export const flashPackThemeOptions = {
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  }),
  palette: {
    primary: {
      main: Colors.brand.jungle,
    },
    secondary: {
      main: Colors.principal.grey70,
    },
    error: {
      main: Colors.system.red100,
      contrastText: Colors.principal.white,
    },
    divider: Colors.principal.grey30,
    principal: Colors.principal,
    system: Colors.system,
    brand: Colors.brand,
  },
  typography: {
    allVariants: {
      color: Colors.principal.black,
    },
    h1: undefined,
    h2: undefined,
    h3: undefined,
    micro: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    pageHeader: {
      fontSize: '26px',
      lineHeight: '30px',
      fontWeight: 700,
    },
    subHeader: {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 600,
    },
    bodySingle: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    bodyPara: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    captionSingle: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    captionPara: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    label: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: letterSpacing(5),
    },
    // remove "Figma" suffix after label/micro typography migration
    // this one should be called "label" but the name is reserved already
    label_Figma: {
      color: Colors.principal.black,
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    ['H1 title']: {
      fontWeight: 700,
      fontSize: '70px',
      lineHeight: '84px',
      fontFamily: 'Larken Bold',
    },
    ['H1']: {
      fontWeight: 700,
      fontSize: '56px',
      lineHeight: '67.2px',
      fontFamily: 'Larken Bold',
    },
    ['H1 small']: {
      fontWeight: 700,
      fontSize: '50px',
      lineHeight: '50.4px',
      fontFamily: 'Larken Bold',
    },
    ['Block Quote']: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '28.8px',
      fontFamily: 'Larken Bold',
    },
    ['H2']: {
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '48px',
    },
    ['H3']: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '38.4px',
    },
    ['H4']: {
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '31.2px',
    },
    ['H5']: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
    ['H6']: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '21.6px',
    },
    ['Body L']: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '36px',
    },
    ['Body L bold']: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '36px',
    },
    ['Body M']: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '28.8px',
    },
    ['Body M bold']: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '120%',
    },
    ['Body S']: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '25.2px',
    },
    ['Body S bold']: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '25.2px',
    },
    ['Additional']: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '120%',
    },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    button: undefined,
    caption: undefined,
    overline: undefined,
    fontFamily: defaultFontFamily,
  },
  components: {
    MuiMenu: {
      defaultProps: {
        elevation: 2,
      },
    },
    Tile: {
      variants: [
        {
          props: { variant: 'accommodation_leading' },
          style: {
            border: 'none',
            color: 'black',
            backgroundColor: Colors.principal.grey30,
            '&:hover': {
              backgroundColor: alpha(Colors.principal.grey50, HOVER_ALPHA),
            },
          },
        },
        {
          props: { variant: 'accommodation_trailing' },
          style: {
            border: 'none',
            color: 'black',
            h3: {
              fontSize: '80%',
            },
            backgroundColor: Colors.principal.grey30,
            '&:hover': {
              backgroundColor: alpha(Colors.principal.grey50, HOVER_ALPHA),
            },
          },
        },
        {
          props: { variant: 'transfer' },
          style: {
            marginLeft: 24,
            marginRight: 24,
            lineHeight: '16px',
            padding: '16px 24px 16px 16px',
            color: 'black',
            backgroundColor: Colors.principal.white,
            '&:hover': {
              backgroundColor: alpha(Colors.principal.grey50, HOVER_ALPHA),
            },
          },
        },
        {
          props: { variant: 'activity' },
          style: {
            marginLeft: 24,
            marginRight: 24,
            lineHeight: '16px',
            padding: '16px 24px 16px 16px',
            color: 'black',
            backgroundColor: Colors.principal.white,
            '&:hover': {
              backgroundColor: alpha(Colors.principal.grey50, HOVER_ALPHA),
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      // TODO: add Link behavior
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderTopWidth: 0,
          borderColor: Colors.principal.grey50,
          marginTop: '24px',
          marginBottom: '24px',
          borderRadius: '100px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'secondary' },
          style: () => ({
            color: Colors.brand.jungle,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: Colors.principal.grey50,
            fontFamily: defaultFontFamily,
            ':hover': {
              color: Colors.principal.white,
              backgroundColor: Colors.brand.jungle,
            },
            '&.Mui-disabled': {
              color: Colors.principal.grey70,
            },
          }),
        },
        {
          props: { variant: 'tertiary' },
          style: () => ({
            color: Colors.brand.jungle,
            fontFamily: defaultFontFamily,
            ':hover': {
              backgroundColor: Colors.system.green10,
            },
          }),
        },
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: Colors.principal.white,
            color: Colors.brand.jungle,
            borderColor: Colors.principal.grey50,
            ':hover': {
              backgroundColor: Colors.brand.jungle,
              color: Colors.principal.white,
            },
            '&.MuiButton-outlinedError': {
              color: Colors.system.red100,
              borderColor: Colors.system.red100,
            },
            '&.MuiButton-outlinedError:hover': {
              backgroundColor: Colors.system.red100,
              color: Colors.principal.white,
            },
          },
        },
        {
          props: { variant: 'light-contained' },
          style: {
            color: Colors.brand.jungle,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: Colors.principal.white,
            backgroundColor: Colors.principal.white,
            '&:hover': {
              color: Colors.principal.white,
            },
            '&.Mui-disabled': {
              color: Colors.principal.grey70,
            },
          },
        },
        {
          props: { variant: 'light-outlined' },
          style: {
            color: Colors.principal.white,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: Colors.principal.white,
            '& .MuiLoadingButton-loadingIndicator': {
              color: Colors.principal.white,
            },
            '&:hover': {
              backgroundColor: darken(Colors.brand.jungle, 1 - HOVER_ALPHA),
            },
            '&.Mui-disabled': {
              color: Colors.principal.grey50,
            },
          },
        },
        {
          props: { variant: 'light-text' },
          style: {
            color: Colors.principal.white,
            '&:hover': {
              backgroundColor: darken(Colors.brand.jungle, 1 - HOVER_ALPHA),
            },
            '&.Mui-disabled': {
              color: Colors.principal.grey50,
            },
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: () => ({
          textTransform: 'none',
          borderRadius: '4px',
          borderWidth: '1px',
          fontFamily: defaultFontFamily,
        }),
        sizeMedium: ({ theme }) => ({
          ...theme.typography.bodySingle,
          padding: theme.spacing(1, 3),
        }),
        sizeSmall: ({ theme }) => ({
          ...theme.typography.captionSingle,
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: defaultFontFamily,
        },
      },
    },
    MuiLink: {
      // TODO: add Link behavior
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.principal.grey70,
          fontFamily: defaultFontFamily,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '& a': {
            color: 'inherit',
            textDecorationColor: 'inherit',
          },
        },
        filledSuccess: {
          backgroundColor: Colors.system.green100,
          color: 'white',
        },
        filledError: {
          backgroundColor: Colors.system.red100,
          color: 'white',
        },
        filledWarning: {
          backgroundColor: Colors.system.amber100,
          color: Colors.principal.black,
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            variant: 'captionSingle',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '50px',
        },
        formControl: {
          height: '100%',
        },
        input: {
          fontFamily: defaultFontFamily,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: 'right',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.principal.white,
          '.MuiChip-root': {
            background: Colors.brand.jungle,
            color: Colors.principal.white,
            '.MuiChip-deleteIcon': {
              color: Colors.principal.white,
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 0,
          padding: 0,
          marginRight: 40,
          fontFamily: defaultFontFamily,
          fontWeight: 500,
          lineHeight: '24px',
          fontSize: '18px',
          textTransform: 'none',
          color: Colors.principal.black,
          borderBottom: 'solid 2px',
          borderBottomColor: Colors.principal.grey50,
          position: 'relative',
          overflow: 'visible',
          '::after': {
            bottom: -2,
            left: '100%',
            position: 'absolute',
            content: '""',
            height: '1px',
            width: '100vw',
            borderBottom: 'solid 2px',
            borderBottomColor: Colors.principal.grey50,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          micro: 'p',
          pageHeader: 'h1',
          subHeader: 'h2',
          bodySingle: 'p',
          bodyPara: 'p',
          captionSingle: 'p',
          captionPara: 'p',
          label: 'p',
          label_Figma: 'p',
          ['H1 title']: 'h1',
          ['H1']: 'h1',
          ['H1 small']: 'h1',
          ['Block Quote']: 'p',
          ['H2']: 'h2',
          ['H3']: 'h3',
          ['H4']: 'h4',
          ['H5']: 'h5',
          ['H6']: 'h6',
          ['Body L']: 'p',
          ['Body L bold']: 'p',
          ['Body M']: 'p',
          ['Body M bold']: 'p',
          ['Body S']: 'p',
          ['Body S bold']: 'p',
          ['Additional']: 'p',
        },
        variant: 'bodySingle',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: Colors.principal.grey50,
          padding: theme.spacing(1),
          '&.Mui-checked': {
            color: Colors.brand.jungle,
          },
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedRight: {
          marginTop: 64,
          zIndex: '1000',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,
          marginRight: theme.spacing(2),
          marginLeft: theme.spacing(1),
        }),
      },
    },
  },
} as ThemeOptions;

export const flashPackTheme = createTheme(flashPackThemeOptions);

export interface FlashPackTypographyVariants {
  micro: React.CSSProperties;
  pageHeader: React.CSSProperties;
  subHeader: React.CSSProperties;
  bodySingle: React.CSSProperties;
  bodyPara: React.CSSProperties;
  captionSingle: React.CSSProperties;
  captionPara: React.CSSProperties;
  label: React.CSSProperties;
  label_Figma: React.CSSProperties;
  ['H1 title']?: React.CSSProperties;
  ['H1']?: React.CSSProperties;
  ['H1 small']?: React.CSSProperties;
  ['Block Quote']?: React.CSSProperties;
  ['H2']?: React.CSSProperties;
  ['H3']?: React.CSSProperties;
  ['H4']?: React.CSSProperties;
  ['H5']?: React.CSSProperties;
  ['H6']?: React.CSSProperties;
  ['Body L']?: React.CSSProperties;
  ['Body L bold']?: React.CSSProperties;
  ['Body M']?: React.CSSProperties;
  ['Body M bold']?: React.CSSProperties;
  ['Body S']?: React.CSSProperties;
  ['Body S bold']?: React.CSSProperties;
  ['Additional']?: React.CSSProperties;
}

declare module '@mui/material/styles' {
  interface TypographyVariants extends FlashPackTypographyVariants {}

  interface TypographyVariantsOptions extends FlashPackTypographyVariants {}

  interface Palette {
    principal: typeof Colors.principal;
    system: typeof Colors.system;
    brand: typeof Colors.brand;
  }

  interface PaletteOptions {
    principal: typeof Colors.principal;
    system: typeof Colors.system;
    brand: typeof Colors.brand;
  }

  interface Components {
    Tile: OverridesStyleRules;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    micro: true;

    pageHeader: true;
    subHeader: true;
    bodySingle: true;
    bodyPara: true;
    captionSingle: true;
    captionPara: true;
    label: true;
    label_Figma: true;

    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;

    /*
    The most up-to-date typography variants; introduced in June, 2024.
    Should be used in all new pages. Will eventually replace the old variants above.
    */
    ['H1 title']: true;
    ['H1']: true;
    ['H1 small']: true;
    ['Block Quote']: true;
    ['H2']: true;
    ['H3']: true;
    ['H4']: true;
    ['H5']: true;
    ['H6']: true;
    ['Body L']: true;
    ['Body L bold']: true;
    ['Body M']: true;
    ['Body M bold']: true;
    ['Body S']: true;
    ['Body S bold']: true;
    ['Additional']: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'light-contained': true;
    'light-outlined': true;
    'light-text': true;
    secondary: true;
    tertiary: true;
  }
}
