import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { composeValidators, Validator } from 'src/design-system/forms/validators';
import { PackLeader } from '@flashpack/graphql';
import {
  CheckboxFormField,
  CheckIcon,
  DeleteTrashCanIcon,
  EmptyAvatarIcon,
  FormControlLabel,
  TextButton,
} from 'design-system';
import { PackLeaderAvatar } from '@src/departures/DeparturePackLeader';

interface PropTypes {
  onDelete: (id: string) => void;
  onSubmit: (packLeader: Omit<PackLeader, 'title'>) => Promise<void>;
  packLeader?: Omit<PackLeader, 'title'>;
}

export const PackLeaderForm: FC<PropTypes> = ({ onDelete, onSubmit, packLeader }) => {
  const { id, name, email, imageUrl, isLead } = packLeader || {};

  const [submitClicked, setSubmitClicked] = useState(false);

  const showErrorWhenSubmitClicked = ({ meta }: { meta: { valid?: boolean } }) => {
    return !meta.valid && submitClicked;
  };

  return (
    <Form<PackLeader>
      initialValues={{ id, name, email, imageUrl, isLead }}
      onSubmit={async (values, form) => {
        await onSubmit(values);
        setSubmitClicked(false);
        form.restart();
      }}
      render={({ values, submitting, handleSubmit, pristine }) => {
        const showSaveButton = !pristine && values.name && values.email;
        const showDeleteButton = pristine && !!packLeader?.id;

        return (
          <form
            onSubmit={(e) => {
              setSubmitClicked(true);
              void handleSubmit(e);
            }}
            style={{ width: '100%', maxWidth: 1300 }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Box
                sx={{
                  flexBasis: 120,
                  display: 'flex',
                  flexShrink: 0,
                  justifyContent: 'flex-end',
                }}
              >
                {submitting ? (
                  <CircularProgress color="secondary" size={32} />
                ) : packLeader ? (
                  <PackLeaderAvatar size={32} packLeader={packLeader} fontSize={13} />
                ) : (
                  <EmptyAvatarIcon sx={{ width: 32, height: 32 }} />
                )}
              </Box>
              <TextField
                placeholder="Pack leader's full name"
                name="name"
                showError={showErrorWhenSubmitClicked}
                fieldProps={{
                  validate: Validator.required,
                }}
              />
              <TextField
                placeholder="Pack leader's email address"
                showError={showErrorWhenSubmitClicked}
                fieldProps={{
                  validate: composeValidators(Validator.required, Validator.validEmail),
                }}
                name="email"
              />
              <TextField
                placeholder="Image url"
                showError={showErrorWhenSubmitClicked}
                name="imageUrl"
              />
              <FormControlLabel
                checked={values.isLead || false}
                label="Is lead?"
                data-testid="create-account-contact-phone"
                sx={{ minWidth: 120 }}
                control={
                  <Field<boolean>
                    name="isLead"
                    label="Is lead?"
                    type="checkbox"
                    defaultValue={false}
                    validateFields={[]}
                    component={CheckboxFormField}
                  />
                }
              />

              <Box
                sx={{
                  flexBasis: 120,
                  display: 'flex',
                  flexShrink: 0,
                  justifyContent: 'flex-start',
                }}
              >
                {showSaveButton && (
                  <TextButton type="submit" startIcon={<CheckIcon />}>
                    Save
                  </TextButton>
                )}
                {showDeleteButton && (
                  <IconButton onClick={() => packLeader?.id && onDelete(packLeader.id)}>
                    <DeleteTrashCanIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </Stack>
          </form>
        );
      }}
    />
  );
};
