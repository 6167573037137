import { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import {
  AccommodationFieldsFragment,
  EditAccommodationLocationDocument,
} from '@flashpack/graphql';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FreeTextFormField } from 'design-system';
import { useMutation } from '@apollo/client';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { Decimal } from 'decimal.js';

interface PropTypes {
  accommodation: AccommodationFieldsFragment;
}

interface LocationFormState {
  name: string;
  latitude: string;
  longitude: string;
}

export const AccommodationLocationTab: FC<PropTypes> = ({ accommodation }) => {
  const { success: successToast, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const [editLocation] = useMutation(EditAccommodationLocationDocument);

  const initialValues: LocationFormState = {
    name: accommodation.location?.name ?? '',
    latitude: accommodation.location?.latitude?.toString() ?? '',
    longitude: accommodation.location?.longitude?.toString() ?? '',
  };

  const handleSubmit = useCallback(
    async (formData: LocationFormState) => {
      await safeMutation(
        editLocation({
          variables: {
            input: {
              id: accommodation.id,
              location: {
                name: formData.name,
                latitude: new Decimal(formData.latitude),
                longitude: new Decimal(formData.longitude),
              },
            },
          },
        }),
        {
          onSuccess: () => {
            successToast('Location successfully updated');
          },
          onError: () => {
            errorToast(
              'There was an issue while updating the location - try again later',
            );
          },
        },
      );
    },
    [accommodation.id, editLocation, safeMutation, successToast, errorToast],
  );

  const validate = (values: LocationFormState) => {
    const errors: Partial<LocationFormState> = {};

    if (!values.name) {
      errors.name = 'Name is required';
    }

    if (!values.latitude) {
      errors.latitude = 'Latitude is required';
    } else if (
      isNaN(Number(values.latitude)) ||
      Number(values.latitude) < -90 ||
      Number(values.latitude) > 90
    ) {
      errors.latitude = 'Invalid latitude (must be between -90 and 90)';
    }

    if (!values.longitude) {
      errors.longitude = 'Longitude is required';
    } else if (
      isNaN(Number(values.longitude)) ||
      Number(values.longitude) < -180 ||
      Number(values.longitude) > 180
    ) {
      errors.longitude = 'Invalid longitude (must be between -180 and 180)';
    }

    return errors;
  };

  return (
    <Stack spacing={3}>
      <Typography variant="Body M" color="text.secondary">
        This is the location pin that will be displayed on the itinerary map on the
        website.
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={(form) => void handleSubmit(form)}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Typography sx={{ color: 'principal.grey70' }}>Location Name</Typography>
                <FreeTextFormField
                  name="name"
                  placeholder="Enter location name"
                  required
                  data-testid="location-name-input"
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{ color: 'principal.grey70' }}>Latitude</Typography>
                <FreeTextFormField
                  name="latitude"
                  placeholder="Enter latitude"
                  required
                  data-testid="latitude-input"
                  helperText="Enter a value between -90 and 90"
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{ color: 'principal.grey70' }}>Longitude</Typography>
                <FreeTextFormField
                  name="longitude"
                  placeholder="Enter longitude"
                  required
                  data-testid="longitude-input"
                  helperText="Enter a value between -180 and 180"
                />
              </Stack>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  loading={submitting}
                  disabled={pristine || submitting}
                  type="submit"
                  variant="contained"
                  color="success"
                  data-testid="location-save-button"
                >
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        )}
      />
    </Stack>
  );
};
