export type Month =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december';

export type Rating = 'Good' | 'Average' | 'Bad';

export type WeatherCondition = 'monsoons' | 'hurricane' | 'snow';

export interface MonthData {
  rating: Rating;
  monsoons: boolean;
  hurricane: boolean;
  snow: boolean;
}

export interface SeasonalityCalendar {
  months: Record<Month, MonthData>;
  notes: string;
}

export const DISPLAY_MONTHS: Record<Month, string> = {
  january: 'Jan',
  february: 'Feb',
  march: 'Mar',
  april: 'Apr',
  may: 'May',
  june: 'Jun',
  july: 'Jul',
  august: 'Aug',
  september: 'Sep',
  october: 'Oct',
  november: 'Nov',
  december: 'Dec',
};

export const DISPLAY_RATINGS: Record<Rating, string> = {
  Good: '🟢',
  Average: '🟡',
  Bad: '🔴',
};

export const DISPLAY_WEATHER_CONDITIONS: Record<WeatherCondition, string> = {
  monsoons: 'Monsoons / Cyclones',
  hurricane: 'Hurricane',
  snow: 'Snow',
};
