import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Checkbox } from 'design-system';
import { Stack } from '@mui/system';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import {
  AdventureWithItinerariesAndDeparturesDocument,
  PackLeadersForItineraryDocument,
  Scalars,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { FC } from 'react';
import { PackLeaderAvatar } from './DeparturePackLeader';
import { Form, Field } from 'react-final-form';
import { SetPackLeadersDocument } from '../../../graphql/generated/graphql';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  itineraryId: Scalars['UUID'];
  departureId: Scalars['UUID'];
  packLeaders: PackLeader[];
}

export type PackLeader = {
  id: string;
};

export const AssignPackLeadersDialog: FC<PropTypes> = ({ open, onClose, ...props }) => {
  return (
    <ConfirmationDialog
      open={open}
      onCancel={onClose}
      title="Assign Pack Leaders"
      hasCustomActions={true}
      maxWidth="sm"
    >
      <AssignPackLeaders onClose={onClose} {...props} />
    </ConfirmationDialog>
  );
};

interface FormValues {
  selectedPackLeaders: Record<string, boolean>;
}

export const AssignPackLeaders: FC<Omit<PropTypes, 'open'>> = ({
  onClose,
  itineraryId,
  departureId,
  packLeaders: currentAssignedPackLeaders,
}) => {
  const theme = useTheme();
  const { safeMutation } = useSafeMutation();
  const { success, error } = useToast();

  const { data, loading } = useQuery(PackLeadersForItineraryDocument, {
    variables: { itineraryId },
  });

  const [setPackLeadersMutation] = useMutation(SetPackLeadersDocument);

  if (loading) {
    return <SkeletonBulletinList count={3} />;
  }

  const packLeaders = [...(data?.itinerary.packLeaders ?? [])].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  if (packLeaders?.length == 0) {
    return (
      <>
        <Typography
          variant="bodySingle"
          sx={{
            lineHeight: '20px',
            color: theme.palette.principal.grey70,
          }}
        >
          There are no pack leaders for this itinerary. Add Pack Leaders in the Pack
          Leaders tab.
        </Typography>
      </>
    );
  }

  const assignPackLeaders = async (selectedIds: string[]) => {
    const successMessage =
      selectedIds.length > 0 ? 'Pack leaders assigned' : 'Pack leaders unassigned';

    const errorMessage = 'Failed to update pack leaders';

    await safeMutation(
      setPackLeadersMutation({
        variables: {
          departureId,
          packLeaders: selectedIds.map((id) => ({ id })),
        },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      }),
      {
        onSuccess: () => success(successMessage),
        onError: () => error(errorMessage),
      },
    );
    onClose();
  };

  // Initialize form values based on current assignments
  const initialValues: FormValues = {
    selectedPackLeaders: packLeaders.reduce(
      (acc, packLeader) => ({
        ...acc,
        [packLeader.id]: currentAssignedPackLeaders.some(
          (currentPackLeader) => currentPackLeader.id === packLeader.id,
        ),
      }),
      {},
    ),
  };

  const handleSubmit = (values: FormValues) => {
    const selectedIds = Object.entries(values.selectedPackLeaders)
      .filter(([_, isSelected]) => isSelected)
      .map(([id]) => id);

    void assignPackLeaders(selectedIds);
  };

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void handleSubmit();
          }}
        >
          <Box>
            {packLeaders.map((packLeader) => (
              <Box
                key={packLeader.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: theme.spacing(1, 3),
                }}
                data-testid="pack-leader-item"
              >
                <Field
                  name={`selectedPackLeaders.${packLeader.id}`}
                  type="checkbox"
                  render={({ input }) => (
                    <Checkbox
                      {...input}
                      data-testid={`assign-pack-leader`}
                      checked={input.checked}
                    />
                  )}
                />
                <Stack gap={1} direction="row" alignItems="center" sx={{ ml: 1 }}>
                  <PackLeaderAvatar size={30} packLeader={packLeader} fontSize={15} />
                  <Typography>{packLeader.name}</Typography>
                  <Typography>({packLeader.email})</Typography>
                </Stack>
              </Box>
            ))}
          </Box>

          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={onClose}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
            <Button fullWidth variant="contained" type="submit" data-testid="save-button">
              Save
            </Button>
          </Stack>
        </form>
      )}
    />
  );
};
