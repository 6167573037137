import { FC } from 'react';
import {
  Select,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  TextField,
} from '@mui/material';
import {
  Month,
  Rating,
  WeatherCondition,
  SeasonalityCalendar,
  MonthData,
} from './seasonality';
import {
  DISPLAY_MONTHS,
  DISPLAY_RATINGS,
  DISPLAY_WEATHER_CONDITIONS,
} from './seasonality';
import { Divider } from 'design-system';

interface SeasonalityCalendarFieldProps {
  calendar: SeasonalityCalendar;
  onChange: (value: SeasonalityCalendar) => void;
}

const RatingLegend: FC = () => (
  <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
    {Object.entries(DISPLAY_RATINGS).map(([rating, icon]) => (
      <Typography
        key={rating}
        variant="captionSingle"
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
      >
        {icon} = {rating}
      </Typography>
    ))}
  </Stack>
);

interface MonthRatingCellProps {
  month: Month;
  rating: Rating;
  onChange: (rating: Rating) => void;
}

const MonthRatingCell: FC<MonthRatingCellProps> = ({ rating, onChange }) => (
  <Select
    size="small"
    value={rating}
    onChange={(e) => onChange(e.target.value as Rating)}
    fullWidth
    sx={{ backgroundColor: 'transparent' }}
  >
    {Object.entries(DISPLAY_RATINGS).map(([rating, icon]) => (
      <MenuItem
        key={rating}
        value={rating}
        sx={{
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        {icon}
      </MenuItem>
    ))}
  </Select>
);

const SeasonalityCalendarField: FC<SeasonalityCalendarFieldProps> = ({
  calendar,
  onChange,
}) => {
  const updateMonthData = (month: Month, updates: Partial<MonthData>) => {
    onChange({
      ...calendar,
      months: {
        ...calendar.months,
        [month]: { ...calendar.months[month], ...updates },
      },
    });
  };

  return (
    <Stack>
      <Typography variant="bodySingle" sx={{ mb: 2, fontWeight: 500 }}>
        Seasonality Calendar
      </Typography>
      <RatingLegend />
      <Divider sx={{ mt: 0 }} />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '8px 4px' }} />
              {Object.entries(DISPLAY_MONTHS).map(([month, abbr]) => (
                <TableCell key={month} align="center" sx={{ padding: '8px 4px' }}>
                  <Typography variant="captionSingle">{abbr}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ padding: '8px 4px' }} />
              {Object.keys(DISPLAY_MONTHS).map((month) => (
                <TableCell key={month} align="center" sx={{ padding: '8px 4px' }}>
                  <MonthRatingCell
                    month={month as Month}
                    rating={calendar.months[month as Month]?.rating || 'Average'}
                    onChange={(rating) => updateMonthData(month as Month, { rating })}
                  />
                </TableCell>
              ))}
            </TableRow>
            {(
              Object.entries(DISPLAY_WEATHER_CONDITIONS) as [WeatherCondition, string][]
            ).map(([condition, label]) => (
              <TableRow key={condition}>
                <TableCell sx={{ padding: '8px 4px' }}>
                  <Typography
                    variant="captionSingle"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {label}
                  </Typography>
                </TableCell>
                {Object.keys(DISPLAY_MONTHS).map((month) => (
                  <TableCell key={month} align="center" sx={{ padding: '8px 4px' }}>
                    <input
                      type="checkbox"
                      checked={calendar.months[month as Month]?.[condition] || false}
                      onChange={(e) =>
                        updateMonthData(month as Month, {
                          [condition]: e.target.checked,
                        })
                      }
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TextField
        label="Anything else to add?"
        value={calendar.notes}
        onChange={(e) => onChange({ ...calendar, notes: e.target.value })}
        multiline
        rows={4}
        sx={{ mt: 2 }}
      />
    </Stack>
  );
};

export const createDefaultCalendar = (): SeasonalityCalendar => ({
  months: Object.fromEntries(
    Object.keys(DISPLAY_MONTHS).map((month) => [
      month,
      { rating: 'Average', monsoons: false, hurricane: false, snow: false },
    ]),
  ) as Record<Month, MonthData>,
  notes: '',
});

export default SeasonalityCalendarField;
