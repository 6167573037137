import { Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { PackLeaderForm } from '@src/packLeaders/PackLeaderForm';
import {
  AddPackLeaderToItineraryDocument,
  PackLeader,
  PackLeadersForItineraryDocument,
  RemovePackLeaderFromItineraryDocument,
} from '@flashpack/graphql';
import { useMutation } from '@apollo/client';
import { TextAddButton } from 'design-system';
import { useSafeMutation } from 'src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import { DeletePackLeaderDialog } from '@src/departures/DeletePackLeaderDialog';

interface PropTypes {
  itineraryId: string;
  packLeaders?: Array<Omit<PackLeader, 'title'>>;
}

const MIN_PACK_LEADER_FORMS = 3;

export const PackLeadersList: FC<PropTypes> = (props) => {
  const { packLeaders = [], itineraryId } = props;
  const [deletePackLeaderId, setDeletePackLeaderId] = useState<string | null>(null);
  const [newPackLeaderForms, setNewPackLeaderForms] = useState(0);
  const { success, error } = useToast();
  const { safeMutation } = useSafeMutation();

  const [addPackLeader] = useMutation(AddPackLeaderToItineraryDocument, {
    refetchQueries: [PackLeadersForItineraryDocument],
  });

  const [removePackLeader] = useMutation(RemovePackLeaderFromItineraryDocument, {
    refetchQueries: [PackLeadersForItineraryDocument],
  });

  const newFormsToAdd =
    Math.max(MIN_PACK_LEADER_FORMS - packLeaders?.length, 0) + newPackLeaderForms;

  const onDelete = async (id: string) => {
    setDeletePackLeaderId(null);
    await safeMutation(
      removePackLeader({ variables: { itineraryId, packLeaderId: id } }),
      {
        onSuccess: () => success('Pack leader removed'),
        onError: () => error('Failed to remove pack leader'),
      },
    );
  };

  const onSave = async (packLeader: Omit<PackLeader, 'title'>) => {
    await safeMutation(
      addPackLeader({
        variables: {
          itineraryId,
          packLeader: {
            id: packLeader.id,
            name: packLeader.name.trim(),
            email: packLeader.email.trim(),
            imageUrl: packLeader.imageUrl?.trim(),
            isLead: packLeader.isLead,
          },
        },
      }),
      {
        onSuccess: () => {
          success('Pack leader saved');
          if (!packLeader.id) {
            setNewPackLeaderForms(Math.max(newPackLeaderForms - 1, 0));
          }
        },
        onError: () => error('Failed to save pack leader'),
      },
    );
  };

  return (
    <Stack direction="column" gap={1} alignItems="center" pt={3}>
      {deletePackLeaderId && (
        <DeletePackLeaderDialog
          open
          onConfirm={() => void onDelete(deletePackLeaderId)}
          onCancel={() => setDeletePackLeaderId(null)}
        />
      )}
      {packLeaders.map((packLeader) => (
        <PackLeaderForm
          onDelete={(id) => setDeletePackLeaderId(id)}
          onSubmit={(v) => onSave(v)}
          key={packLeader.id}
          packLeader={packLeader}
        />
      ))}
      {newFormsToAdd !== 0 &&
        [...Array(newFormsToAdd).keys()].map((key) => (
          <PackLeaderForm
            key={key}
            onDelete={(id) => setDeletePackLeaderId(id)}
            onSubmit={(v) => onSave(v)}
          />
        ))}

      <TextAddButton
        onClick={() => setNewPackLeaderForms(newPackLeaderForms + 1)}
        sx={{ mt: 3 }}
      >
        Add pack leader
      </TextAddButton>
    </Stack>
  );
};
